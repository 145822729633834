import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import VuexPersistence from "vuex-persist";

/// import SecureLS from "secure-ls";
/// const ls = new SecureLS({
	// encodingType: "base64",
	/// isCompression: false,
	// encryptionSecret: process.env.VUE_APP_SECURE_LS_KEY
	// encryptionSecret: "testit"
/// });

import auth from "./modules/auth/authStore";
import approvals from "./modules/approvals/approvalsStore";
import loader from "./modules/loader";
import userData from "./modules/userData";
import users from "./modules/registration/usersStore";
import customers from "./modules/registration/customersStore";
import employees from "./modules/registration/employeesStore";
import vendors from "./modules/registration/vendorsStore";
import groups from "./modules/registration/groupsStore";
import banking from "./modules/finance/banking/bankingStore";
import cashvault from "./modules/finance/banking/cashvaultStore";
import standingorder from "./modules/finance/banking/standingorderStore";
import failedstandingorder from "./modules/finance/banking/failedstandingorderStore";
import telleraccount from "./modules/finance/banking/tellerAccountStore";
import vaultaccount from "./modules/finance/banking/vaultAccountStore";
import financeaccounts from "./modules/finance/accounts/accountsStore";
import reports from "./modules/finance/reports/reportStore";
import companies from "./modules/registration/companiesStore";
import paybills from "./modules/registration/paybillsStore";
import mpesasettings from "./modules/registration/mpesasettingsStore";
import branches from "./modules/registration/branchesStore";
import shared from "./modules/finance/shared/sharedStore";
import years from "./modules/finance/financialyears/financialYearsStore";
import mpesapayments from "./modules/finance/payments/mpesaStore";
import roles from "./modules/accessMgt/rolesStore";
import permissions from "./modules/accessMgt/permissionsStore";
import deposits from "./modules/deposits/depositsStore";

import loanapplication from "./modules/loans/loanApplicationStore";
import loanreports from "./modules/loans/loanReportStore";
import loans from "./modules/loans/loanStore";
import loanschedule from "./modules/loans/loanScheduleStore";
import loanofferletter from "./modules/loans/loanOfferLetterStore";

import businessappraisals from "./modules/loans/businessappraisalStore";
import businesscategories from "./modules/loans/businesscategoriesStore";
import loanapplicationapprovals from "./modules/loans/loanapplicationapprovalStore";
import sms from "./modules/messaging/sms/smsStore";
import emails from "./modules/messaging/emails/emailsStore";
import recommends from "./modules/messaging/sms/recommendsStore";
import fileuploads from "./modules/general/fileUploadsStore";
import loanexposurelimits from "./modules/loans/loanExposureLimitStore";
import collaterals from "./modules/loans/collateralStore";
import loansecurities from "./modules/loans/loanSecurityStore";
import financeexports from "./modules/report/financeReportStore";
import registrationexports from "./modules/report/registrationReportStore";
import loanstatuses from "./modules/registration/loanstatusStore";
import depositstatuses from "./modules/registration/depositstatusStore";
import shariacontracts from "./modules/registration/shariacontractStore";
import depositcontracts from "./modules/registration/depositcontractStore";
import taxes from "./modules/registration/taxStore";
import depositcharges from "./modules/registration/depositChargesStore";
import groupcategories from "./modules/registration/groupcategoriesStore";
import groupsubcategories from "./modules/registration/groupsubcategoriesStore";
import grouprequests from "./modules/registration/grouprequestsStore";
import groupadmins from "./modules/registration/groupadminsStore";
import userstagings from "./modules/registration/usersstagingStore";
import nextofkins from "./modules/registration/nextOfKinStore";
import userbanks from "./modules/registration/userBankStore";
import customercalls from "./modules/registration/customercallsStore";
import customeremployeeallocations from "./modules/registration/customeremployeeallocationsStore";
import banks from "./modules/registration/bankStore";
import loanappraisal from "./modules/registration/loanappraisalStore";
import loanappraisaldetail from "./modules/registration/loanappraisaldetailStore";
import documentationfee from "./modules/registration/documentationfeeStore";
import productcategory from "./modules/registration/productcategoryStore";
import teams from "./modules/registration/teamStore";
import teammembers from "./modules/registration/teamMemberStore";
import customer_allocations from "./modules/registration/customersAllocationStore";

import membertypes from "./modules/registration/memberTypeStore";

// general
import statuses from "./modules/general/statusesStore";
import statistics from "./modules/general/statisticsStore";
import maritalstatuses from "./modules/general/maritalStatusesStore";
import notifications from "./modules/general/notificationStore";
import countries from "./modules/general/countriesStore";
import counties from "./modules/general/countiesStore";
import constituencies from "./modules/general/constituenciesStore";
import wards from "./modules/general/wardsStore";
import translations from "./modules/general/translationStore";

// message templates
import emailtemplates from "./modules/administration/messagetemplates/emailtemplatesStore";
import smstemplates from "./modules/administration/messagetemplates/smstemplatesStore";

// message templates master
import emailtemplatesmaster from "./modules/administration/messagetemplatesmaster/emailtemplatesmasterStore";
import smstemplatesmaster from "./modules/administration/messagetemplatesmaster/smstemplatesmasterStore";

// maker checker
import makercheckers from "./modules/administration/makerchecker/makercheckerStore";
import makercheckersections from "./modules/administration/makerchecker/makercheckersectionsStore";
import makercheckersectionuserlevels from "./modules/administration/makerchecker/makercheckersectionuserlevelsStore";
import makercheckersectionsmaster from "./modules/administration/makerchecker/makercheckersectionsmasterStore";

// customer data
import customerdata from "./modules/customer/customerdataStore";
import recommendsdata from "./modules/customer/recommendsdataStore";
import groupsdata from "./modules/customer/groupsdataStore";
import userbanksdata from "./modules/customer/userbankdataStore";
import nextofkinsdata from "./modules/customer/nextofkinsdataStore";
import savingsdata from "./modules/customer/savingsdataStore";
import loansdata from "./modules/customer/loansdataStore";

/* administration */
import productsettings from "./modules/settings/productSettingsStore";
import companysettings from "./modules/administration/companySettingsStore";
import companycolors from "./modules/administration/companyColorsStore";
import depositsettings from "./modules/settings/depositSettingsStore";
import paymentmethods from "./modules/settings/paymentMethodsStore";
import fixedDepositAccounts from "./modules/registration/fixedDepositAcountStore";
import currentDepositAccounts from "./modules/registration/currentDepositAccountStore";
import shareDepositAccounts from "./modules/registration/shareDepositAccountStore";
import registrationFeeSettings from "./modules/administration/registrationFeeSettings/registrationFeeSettingsStore";
import membershipsettings from "./modules/administration/membershipSettingsStore";
import failedjobs from "./modules/settings/failedJobsStore";

// import auth from "./modules/auth";
// import shared getters, actions and mutations

import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

import { EncryptStorage } from "encrypt-storage";

const key = "kKPKUvc1Olb2Z9R1tLydqPfx0Z5W+nzbZx9UYmxl6q85PAQsX4bC8wHyw7SLnwGX";
const encryptStorage = new EncryptStorage(key, {
	storageType: "sessionStorage",
});

const vuexLocal = new VuexPersistence({
	/* storage: window.sessionStorage, */
	storage: encryptStorage,
});

const store = createStore({
	// shared state data - used globally
	state: {
		loadingStatus: false,
		currentPageTitle: "",
		successMessage: null,
		error: null,
	},

	// plugins: [createPersistedState()],
	/* plugins: [
		createPersistedState({
			storage: {
				getItem: (key) => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: (key) => ls.remove(key),
				// storage: window.sessionStorage
			},
		}),
	], */

	plugins: [vuexLocal.plugin],

	// shared getters, actions and mutations - used globally
	getters,
	actions,
	mutations,

    // module state, getters, actions and mutations
    modules: {
        auth,
        loader,
        users,
        customers,
        employees,
        vendors,
        groups,
        years,
        shared,
        banking,
        reports,
        branches,
        financeaccounts,
        userData,
        companies,
        paybills,
        roles,
        cashvault,
        telleraccount,
        vaultaccount,
        permissions,
        approvals,
        deposits,
        productsettings,
        companysettings,
        paymentmethods,
        depositsettings,
        loanapplication,
        collaterals,
        loansecurities,
        businessappraisals,
        businesscategories,
        loanapplicationapprovals,
        sms,
        emails,
        recommends,
        fileuploads,
        loanexposurelimits,
        financeexports,
        registrationexports,
        loanstatuses,
        depositstatuses,
        loans,
        loanreports,
        shariacontracts,
        depositcontracts,
        taxes,
        depositcharges,
        groupcategories,
        groupsubcategories,
        grouprequests,
        groupadmins,
        userstagings,
        nextofkins,
        userbanks,
        banks,
        standingorder,
        loanappraisaldetail,
        loanappraisal,
        failedstandingorder,
        customercalls,
        customeremployeeallocations,
        productcategory,
        documentationfee,
        loanschedule,
        loanofferletter,
		mpesasettings,
        fixedDepositAccounts,
        currentDepositAccounts,
        shareDepositAccounts,
        teams,
        teammembers,
        customer_allocations,
        // general
        statuses,
        statistics,
        maritalstatuses,
        notifications,
        countries,
        counties,
        constituencies,
        wards,
        translations,

		// message templates
		emailtemplates,
		smstemplates,

		// message templates master
		emailtemplatesmaster,
		smstemplatesmaster,

		// maker checker
		makercheckers,
		makercheckersections,
		makercheckersectionuserlevels,
		makercheckersectionsmaster,

        // settings
        membershipsettings,

		// customer stores
		recommendsdata,
		groupsdata,
		userbanksdata,
		customerdata,
		nextofkinsdata,
		savingsdata,
		loansdata,

		// administration
		companycolors,
        registrationFeeSettings,

        //finance
        mpesapayments,
        //configurations
        failedjobs,
        membertypes
	},
});

// console.log("Securels-key", process.env.VUE_APP_SECURE_LS_KEY);

export default store;

/* export function resetState() {
    store.replaceState(initialStateCopy)
} */
