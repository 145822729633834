const loanRoutes = [

    {

        name: 'loanHome',
        path: '',
        component: () =>
            import('./ListLoans.vue'),
        meta: {
            auth: true,
            title: 'Finance Listing',
            breadcrumb: 'Finance Listing',
            permission: ['view loan']
        }

    },

    {
        path: 'customer-loans/:customerId',
        name: 'customerLoans',
        component: () =>
            import('./ListCustomerLoans.vue'),
        meta: {
            auth: true,
            title: 'Customer Loans',
            breadcrumb: 'Customer Loans',
            permission: ['view loan']
        },
        children: [

            {

                name: 'showCustomerLoan',
                path: ':loanId',
                component: () =>
                    import('./ListCustomerLoans.vue'),
                meta: {
                    auth: true,
                    title: 'Showing Customer Loan',
                    breadcrumb: 'Showing Customer Loan',
                    permission: ['view loan']
                }


            },

        ]
    },





    {
        path: 'show/:id',
        name: 'showLoan',
        component: () =>
            import('./ShowLoan.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan Application',
            breadcrumb: 'Showing Loan Application',
            permission: ['view loan'],
            section: 'loanapplication'
        }

    },

    {
        path: 'viewaccount/:id',
        name: 'viewCustomerAccount',
        component: () =>
            import('./ViewAccount.vue'),
        meta: {
            auth: true,
            title: 'View Account',
            breadcrumb: 'View Account',
            permission: []
        }

    },

    {
        path: 'partialpayments',
        name: 'partialpaymentsreport',
        component: () =>
            import('./report/PartialPaymentsReport.vue'),
        meta: {
            auth: true,
            title: 'Partial Payments Report',
            breadcrumb: 'Partial Payments Report',
            permission: []
        }

    },

    {
        path: 'defaulters',
        name: 'defaultersreport',
        component: () =>
            import('./report/LoanDefaultersReport.vue'),
        meta: {
            auth: true,
            title: 'Defaulters Report',
            breadcrumb: 'Defaultes Report',
            permission: []
        }

    },

    {
        path: 'disbursementreport',
        name: 'facilitydisbursementreport',
        component: () =>
            import('./report/LoanPaymentReport.vue'),
        meta: {
            auth: true,
            title: 'Disbursement Report',
            breadcrumb: 'Disbursement Report',
            permission: []
        }

    },

    {
        path: 'branchperfomance',
        name: 'branchperfomancereport',
        component: () =>
            import('./report/BranchPerfomanceReport.vue'),
        meta: {
            auth: true,
            title: 'Branch Perfomance Report',
            breadcrumb: 'Branch Perfomance Report',
            permission: []
        }

    },

    {
        path: 'clientfacilities',
        name: 'clientfacilitiesreport',
        component: () =>
            import('./report/ClientFacilitiesReport.vue'),
        meta: {
            auth: true,
            title: 'Client Facilities Report',
            breadcrumb: 'Client Facilities Report',
            permission: []
        }

    },

    {
        path: 'dailydisbursement',
        name: 'dailydisbursementreport',
        component: () =>
            import('./report/DailyDisbursementReport.vue'),
        meta: {
            auth: true,
            title: 'Daily Disbursement Report',
            breadcrumb: 'Daily Disbursement Report',
            permission: []
        }

    },

    {
        path: 'monthlydisbursement',
        name: 'monthlydisbursementreport',
        component: () =>
            import('./report/MonthlyDisbursementReport.vue'),
        meta: {
            auth: true,
            title: 'Monthly Disbursement Report',
            breadcrumb: 'Monthly Disbursement Report',
            permission: []
        }

    },

    {
        path: 'teamperfomance',
        name: 'teamperfomancereport',
        component: () =>
            import('./report/TeamPerfomanceReport.vue'),
        meta: {
            auth: true,
            title: 'Team Perfomance Report',
            breadcrumb: 'Team Perfomance Report',
            permission: []
        }

    },

    {
        path: 'paymentanalysis',
        name: 'paymentanalysisreport',
        component: () =>
            import('./report/PaymentAnalysisReport.vue'),
        meta: {
            auth: true,
            title: 'Payment Analysis Report',
            breadcrumb: 'Payment Analysis Report',
            permission: []
        }

    },

];

export default loanRoutes;
